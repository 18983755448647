import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";
import { SampleDocument } from "../../../courses/components/CourseDetailSampleExamTab";
import { useTranslation } from "react-i18next";

export const ProgrammeSection = () => {
  const { t } = useTranslation();
  return (
    <section className="programme" id="programme">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Conference Programme"}
              />
              <SampleDocument
                title={"Full Conference Programme"}
                url={
                  "https://drive.google.com/file/d/1alw7FIUi5_g6rJrGxjXKwfUX0hGH8dhH/view?usp=sharing"
                }
                buttonText={t("View")}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
